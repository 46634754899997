<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-n2">
        <h4 style="font-weight: 500">Jeton de Connexion</h4>
        </div>
        <hr  />
        <mdb-card class="z-depth-1">
            <mdb-card-body class="card-reversed">
                <form  @submit.prevent="modalPasswordCheck.show = true" >
                    <mdb-row>
                        <mdb-col sm="12" md="6">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                             :disabled="!$gate.hasPermission(['edit::setting'])" 
                            :validation="$store.state.setting.errors.jeton_expire_at ? true :false" :invalidFeedback="$store.state.setting.errors.jeton_expire_at"
                            v-model="form.jeton_expire_at"
                            v-mask="'##/##/####'"
                             outline label="Date d'expiration" size="lg" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="6">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                             :disabled="!$gate.hasPermission(['edit::setting'])"
                            :validation="$store.state.setting.errors.nim ? true :false" :invalidFeedback="$store.state.setting.errors.nim"
                            v-model="form.nim"
                             outline label="NIM" size="lg" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="12" >
                            <mdb-input wrapperClass="card-reversed "  :inputClass="(jetonhidden)? 'filterjeton disabled btn-show-j':''+' border-reversed color-normal-reversed'" labelClass=" color-normal-reversed"
                             :disabled="!$gate.hasPermission(['edit::setting'])" 
                            :validation="$store.state.setting.errors.jeton ? true :false" :invalidFeedback="$store.state.setting.errors.jeton"
                            v-model="form.jeton"
                            type="textarea" :rows="3"
                             outline label="Contenu du jeton" size="lg" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" class="d-flex justify-content-end">
                            <button v-if="$gate.hasPermission(['edit::setting'])" @click="controlJeton()" type="button" class="btn btn-info btn-md" > 
                               <i :class="(jetonhidden)?'fas fa-eye':'fas fa-eye-slash'"></i> <span v-if="jetonhidden">Jeton</span>
                               <span v-else>Jeton</span>
                            </button>
                            <button v-if="$gate.hasPermission(['edit::setting'])" type="submit" :disabled="submitBtn" class="btn btn-primary btn-md" > 
                                <span v-if="!submitBtn">Sauvegarder</span>
                                <span v-if="submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-if="submitBtn" class="pl-2">Loading...</span>
                            </button>
                        </mdb-col>
                    </mdb-row>
                </form>
            </mdb-card-body>
        </mdb-card>

        <mdb-modal
            centered
            size="md"
            direction="top"
            position="top"
            :show="modalPasswordCheck.show"
            @close="modalPasswordCheck.show = false" 
        > 
            <mdb-modal-body class="modal-tec card-reversed">
                <div class="modal-times-tec "  @click="modalPasswordCheck.show = false" > <i class="fas fa-times"></i> </div>
                    <mdb-modal-title class="text-primary white-text" style="font-weight:500 !important; font-size:1.5rem !important;">Confirmer</mdb-modal-title>
                    <form @submit.prevent="checkPasswordServer()">
                    <mdb-input
                    wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                            v-model="formPassword.password"
                            :validation="$store.state.user.errors.password ? true :false" :invalidFeedback="$store.state.user.errors.password"
                            outline label="Mot de passe" size="lg" type="password" ></mdb-input>
                    <div class="d-flex justify-content-end">
                        <mdb-btn size="md" color="grey"  @click="modalPasswordCheck.show = false">Fermer</mdb-btn>
                        <mdb-btn size="md" type="submit" color="primary"  :disabled="modalPasswordCheck.bttn" >
                            <span v-if="!modalPasswordCheck.bttn">Confirmer</span>
                            <span v-if="modalPasswordCheck.bttn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span v-if="modalPasswordCheck.bttn" class="pl-2">Loading...</span>
                        </mdb-btn>
                    </div>
                </form>
            </mdb-modal-body>

        </mdb-modal>
    </div>
</template>

<script>
import {
    mdbInput,
    mdbRow,mdbCol,
    mdbCard,mdbCardBody,
    mdbBtn,
    mdbModal,mdbModalTitle,mdbModalBody,
} from 'mdbvue';
export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - PARAMETRE BANQUE',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbInput,
        mdbRow,mdbCol,
        mdbCard,mdbCardBody,
        mdbModal,mdbModalTitle,mdbModalBody,
        mdbBtn,
    },
    data(){
        return{
            modalPasswordCheck: {
                show: false,
                edit: false,
                title: "Confirmer",
                content: {
                    content: {},
                },
                bttn:false
            },
            submitBtn:false,
            formPassword:{
                password:""
            },
            jetonhidden:true,
            form:{
                id:this.$store.state.auth.company.setting.id,
                jeton:this.$store.state.auth.company.setting.jeton,
                nim:this.$store.state.auth.company.setting.nim,
                jeton_expire_at:this.$store.state.auth.company.setting.jeton_expire_at 
            }
        }
    },

    methods:{
        async controlJeton(){
            this.jetonhidden = !this.jetonhidden
        },
        async checkPasswordServer(){
            this.$nprogress.start()
            this.$store.commit('user/SET_PWD', '')
            this.modalPasswordCheck.bttn = !this.modalPasswordCheck.bttn
            await this.$store.dispatch('user/checkPassword', this.formPassword)
            .then(() => {
                this.$nprogress.done()
                this.modalPasswordCheck.bttn = !this.modalPasswordCheck.bttn
                this.modalPasswordCheck.show = false
                this.formPassword.password = ""
                this.updateJeton()
			})
            .catch((error) => {
                this.$nprogress.done()
                this.modalPasswordCheck.bttn = !this.modalPasswordCheck.bttn
                if (error.response.data.errors) {
                    this.$store.commit('user/SET_PWD', error.response.data.errors.password)
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})
        },

        async updateJeton() {
            this.$nprogress.start()
            this.$store.commit('setting/SET_CLEAN')
            this.submitBtn = !this.submitBtn
			await this.$store.dispatch('setting/updateJeton', this.form)
			.then((response) => {
                this.$nprogress.done()
                this.submitBtn = !this.submitBtn
                this.$notify({
                     
                    message: response.data.message,
                    type: 'success'
                })
                this.$store.dispatch('auth/attempt',localStorage.getItem('token'));
			})
			.catch((error) => {
                this.$nprogress.done()
                this.submitBtn = !this.submitBtn
                if (error.response.data.errors) {
                    this.$store.commit('setting/SET_JETON', error.response.data.errors.jeton)
                    this.$store.commit('setting/SET_NIM', error.response.data.errors.nim)
                    this.$store.commit('setting/SET_JETON_EXP', error.response.data.errors.jeton_expire_at)
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },
    },
}
</script>